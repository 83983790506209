import { useState } from "react";
import moment from "moment";
import axiosInstance from "../../utils/axios";
import { Input } from "antd";
import { DownloadLink } from "../../components/common/Button";
import { PAYMENTS_API } from "../../constants/api";
import { downloadThroughAnchorLink } from "../../utils/helperUtil";

export const FilterInput = ({ title }) => (
  <>
    <p style={{ paddingBottom: 5 }}>{title}</p>
    <Input />
  </>
);

function usePayments() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [payments, setPayments] = useState([]);

  // Get list of Payments
  const getPayments = () => {
    setLoading(true);
    axiosInstance
      .get("payments")
      .then((res) => {
        // handle success
        setPayments(res.data.results);
        setLoading(false);
      })
      .catch((err) => {
        // handle error
        setLoading(false);
        setError(err);
      });
  };

  const paymentsTableData = (data) => [
    ...data.map((item) => {
      const row = {
        ref: item.ref,
        amount: item.amount.toLocaleString(),
        bank: item.bank,
        taxhead: item.taxhead,
        account: item?.account,
        period_name: item.period_name,
        payment_date: moment(item.payment_date).format("DD-MM-YYYY"),
        owner_name: item.owner_name,
        municipality_name: item.municipality_name,
        division_name: item.division_name,
        parish_name: item.parish_name,
        village_name: item.village_name,
        street_name: item.street_name,
        certificate: (
          <DownloadLink
            onClick={() =>
              downloadThroughAnchorLink(
                `${PAYMENTS_API}print-receipt/?paid_id=${item?.id}`,
                "receipt"
              )
            }
            title="[Receipt]"
          />
        ),
      };
      return row;
    }),
  ];

  const paymentsTableColumns = [
    { id: "ref", header: "PRN", filter: {} },
    { id: "amount", header: "Amount(UGX)", filter: {} },
    { id: "bank", header: "Bank", filter: {} },
    { id: "taxhead", header: "Permit Type", filter: {} },
    { id: "account", header: "Account", filter: {} },
    { id: "period_name", header: "Period Name", filter: {} },
    { id: "payment_date", header: "Payment Date", filter: {} },
    { id: "owner_name", header: "Tax Payer Name", filter: {} },
    { id: "municipality_name", header: "Municipality", filter: {} },
    { id: "division_name", header: "Division", filter: {} },
    { id: "parish_name", header: "Parish", filter: {} },
    { id: "village_name", header: "Village", filter: {} },
    { id: "street_name", header: "Street", filter: {} },
    { id: "certificate", header: "Actions" },
  ];

  const paymentsTableColumns_PublicUser = [
    {
      dataIndex: "ref",
      title: <FilterInput title="PRN" />,
      fixed: true,
    },
    { dataIndex: "amount", title: <FilterInput title="Amount(UGX)" /> },
    { dataIndex: "bank", title: <FilterInput title="Bank" /> },
    { dataIndex: "taxhead", title: <FilterInput title="Permit Type" /> },
    { dataIndex: "account", title: <FilterInput title="Account" /> },
    { dataIndex: "period_name", title: <FilterInput title="Period Name" /> },
    { dataIndex: "payment_date", title: <FilterInput title="Payment Date" /> },
    { dataIndex: "owner_name", title: <FilterInput title="Owner Name" /> },
    {
      dataIndex: "municipality_name",
      title: <FilterInput title="Municipality" />,
    },
    { dataIndex: "division_name", title: <FilterInput title="Division" /> },
    { dataIndex: "parish_name", title: <FilterInput title="Parish" /> },
    { dataIndex: "village_name", title: <FilterInput title="Village" /> },
    { dataIndex: "street_name", title: <FilterInput title="Street" /> },
    { dataIndex: "certificate", title: "Actions" },
  ];

  return {
    paymentsTableData,
    paymentsTableColumns,
    paymentsTableColumns_PublicUser,
    getPayments,
    payments,
    error,
    loading,
  };
}

export default usePayments;
